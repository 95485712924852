const translations = {
    home: {
        nav: {
            home: 'Accueil',
            download: 'Télécharger',
            about: 'À propos',
            faq: 'FAQ',
            contact: 'Contact',
        },
        hero: {
            content: 'Assurez la qualité de votre location avec des contrôles de véhicules fiables et certifiés.',
            callToAction: 'Installez l\'application maintenant !',
        },
        download: {
            title: 'Téléchargez l\'application dès maintenant !',
            desc: 'Obtenez l\'application sur votre appareil et commencez à l\'utiliser dès aujourd\'hui.',
            appleDownload: 'Télécharger sur l\'App Store',
            androidDownload: 'Obtenir sur Google Play',
        },
        about: {
            title1: 'Laissez-nous prendre soin de votre véhicule',
            desc1: 'Carchecker est une plateforme qui connecte les utilisateurs avec des professionnels certifiés pour l\'inspection et le nettoyage des véhicules. Notre objectif est de garantir que votre véhicule est en parfait état pour votre location.',
            btn1: 'Commencer maintenant',
            title2: 'Devenez un Cheker',
            desc2: 'Vous pouvez également devenir un Cheker et offrir vos services aux utilisateurs. Rejoignez notre plateforme et commencez à gagner de l\'argent en fournissant des services de qualité.',
            btn2: 'En savoir plus',
        },
        faq: {
            title: 'Questions Fréquemment Posées',
            questions: [
                {
                    question: 'Qu\'est-ce que CARCHECKER ?',
                    answer: 'Carchecker est une plateforme qui vous permet de demander des services tels que l\'inspection et le nettoyage de véhicules directement depuis votre téléphone. Des professionnels certifiés se rendent à l\'emplacement de votre véhicule à l\'heure que vous choisissez pour fournir le service.'
                },
                {
                    question: 'Comment fonctionne Carchecker ?',
                    answer: 'Carchecker vous permet de planifier des services d\'inspection et de nettoyage de véhicules à partir de votre téléphone. Les professionnels certifiés se rendent à l\'endroit et à l\'heure que vous choisissez pour effectuer le service.'
                },
                {
                    question: 'Carchecker est-il disponible dans ma ville ?',
                    answer: 'Carchecker est en cours de déploiement dans plusieurs villes. Veuillez vérifier la disponibilité sur notre application ou notre site web pour voir si nous opérons dans votre région.'
                },
                {
                    question: 'Comment puis-je devenir un Cheker ?',
                    answer: 'Pour devenir un Cheker, vous pouvez vous inscrire sur notre plateforme. Une fois inscrit, vous aurez accès aux outils nécessaires pour commencer à offrir vos services.'
                },
                {
                    question: 'Quels sont les avantages d\'utiliser Carchecker ?',
                    answer: 'Utiliser Carchecker vous assure que votre véhicule est en bon état pour vos locations grâce à des inspections et nettoyages certifiés, tout en simplifiant la planification et le suivi des services.'
                },
                {
                    question: 'Comment puis-je contacter Carchecker ?',
                    answer: 'Vous pouvez contacter Carchecker via la section contact de notre site web ou de notre application, ou en envoyant un e-mail à notre support client à support@carchecker.com.'
                }
            ],
        },
        contact: {
            // Add translated contact details if necessary
        }
    },
    footer: {
        termsOfUse: 'Conditions d\'utilisation',
        termsOfSale: 'Conditions Générales de Vente',
        privacyPolicy: 'Politique de Confidentialité',
        cookiesPolicy: 'Politique des Cookies',
        copyright: 'Tous droits réservés.',
    },
    emailConfirmed: {
        header: 'Email confirmé',
        desc: 'Votre email a été confirmé avec succès. Vous pouvez maintenant retourner à l\'application et vous connecter.',
        gotoApp: 'Veuillez retourner à l\'application mobile pour continuer.',
    },
    forgotPassword: {
        header: 'Mot de passe oublié',
        email: {
            label: 'Adresse e-mail',
            errorMessage: 'Veuillez entrer votre adresse e-mail',
            validEmailRequired: 'Veuillez entrer une adresse e-mail valide',
            placeholder: 'Entrez votre adresse e-mail'
        },
        submit: 'Soumettre',
        success: 'Succès',
        successDesc: 'Un lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception et suivre les instructions.',
        error: 'Erreur',
        submitting: 'Envoi en cours...',
        errors: {
            tryAgainLater: 'Une erreur inattendue s\'est produite. Veuillez réessayer plus tard.',
            resetLinkNotSent: 'Une erreur s\'est produite lors de l\'envoi du lien de réinitialisation.'
        }
    },

    notFound: {
        message: 'Oups ! La page que vous recherchez n\'existe pas.',
        goHome: 'Retourner à l\'accueil',
    },

    resetPassword: {
        header: 'Réinitialiser le mot de passe',
        newPassword: {
            label: 'Nouveau mot de passe',
            placeholder: 'Entrez votre nouveau mot de passe',
            emptyError: 'Veuillez entrer votre nouveau mot de passe !',
            tooShortError: 'Le mot de passe doit contenir au moins 8 caractères.',
        },
        confirmPassword: {
            label: 'Confirmez le nouveau mot de passe',
            placeholder: 'Confirmez votre nouveau mot de passe',
            emptyError: 'Veuillez confirmer votre nouveau mot de passe !',
            noMatchError: 'Les deux mots de passe ne correspondent pas !',
        },
        submitting: 'Envoi en cours...',
        submit: 'Soumettre',
    },

    chekerVerif: {
        text: "Redirection en cours, veuillez patienter...",
        invalidID: "Identifiant non valide.",
        failedRedirect: "Échec de la redirection. Veuillez réessayer plus tard.",
        errorOccurred: "Une erreur est survenue lors de la récupération du lien de redirection.",
        noRedirect: "Aucun lien de redirection disponible."
    },

    chekerVerificationFinished: {
        title: 'Vos mises à jour ont été soumises avec succès !',
        subTitle: 'Votre soumission est en cours de vérification. Veuillez retourner à l\'application mobile pour continuer.',
    },

    ToU: {
        header: "Conditions Générales d'Utilisation (CGU)",
        content: [
            {
                "label": "1. Acceptation des Conditions",
                "desc": "L'utilisation de l'application Carchecker (« Application ») est soumise aux présentes Conditions Générales d'Utilisation (« CGU »). En utilisant l'Application, vous acceptez ces CGU dans leur intégralité. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser l'Application."
            },
            {
                "label": "2. Description du Service",
                "desc": "Carchecker permet aux utilisateurs de demander des services liés à la vérification et l'entretien automobile via l'Application. Les services sont fournis par des prestataires partenaires (« Prestataires ») inscrits sur la plateforme."
            },
            {
                "label": "3. Inscription et Compte",
                "desc": "Pour utiliser les services de l'Application, vous devez créer un compte en fournissant des informations personnelles précises et complètes. Vous êtes responsable de la confidentialité de vos identifiants et de toutes les activités effectuées sur votre compte."
            },
            {
                "label": "4. Obligations des Utilisateurs",
                "desc": "Les utilisateurs doivent :\n- Fournir des informations exactes lors de la demande de services.\n- Respecter les rendez-vous et conditions établis avec les Prestataires.\n- Ne pas utiliser l'Application à des fins illégales ou interdites."
            },
            {
                "label": "5. Responsabilité",
                "desc": "L’application Carchecker agit uniquement en tant qu'intermédiaire entre les utilisateurs et les Prestataires. Nous ne garantissons pas la qualité des prestations fournies par les Prestataires et ne sommes pas responsables des litiges entre les utilisateurs et les Prestataires."
            },
            {
                "label": "6. Confidentialité",
                "desc": "Vos données personnelles sont collectées et traitées conformément à notre Politique de Confidentialité. Nous nous engageons à protéger vos informations et à ne pas les divulguer à des tiers sans votre consentement, sauf si la loi l'exige."
            },
            {
                "label": "7. Propriété Intellectuelle",
                "desc": "Tous les droits de propriété intellectuelle liés à l'Application et à son contenu sont la propriété exclusive de Carchecker ou de ses concédants de licence. Vous n'êtes pas autorisé à utiliser ces éléments sans autorisation préalable."
            },
            {
                "label": "8. Modifications des CGU",
                "desc": "Carchecker se réserve le droit de modifier ces CGU à tout moment. Les modifications seront publiées sur l'Application et entreront en vigueur dès leur mise en ligne. Votre utilisation continue de l'Application constitue une acceptation des modifications."
            },
            {
                "label": "9. Résiliation",
                "desc": "Carchecker se réserve le droit de suspendre ou de résilier votre compte si vous violez les CGU ou si vous utilisez l'Application de manière abusive."
            },
            {
                "label": "10. Loi Applicable et Juridiction",
                "desc": "Les présentes CGU sont régies par le droit français. Tout litige relatif à l'Application sera soumis à la compétence exclusive des tribunaux français."
            }
        ]
    },
    GTS: {
        header: 'Conditions Générales de Vente (CGV)',
        content: [
            {
                "label": "1. Champ d'application",
                "desc": "Les présentes Conditions Générales de Vente (« CGV ») s'appliquent à toutes les prestations de services demandées via l'application Carchecker (« Application ») et fournies par les Prestataires inscrits sur la plateforme."
            },
            {
                "label": "2. Commande et Confirmation",
                "desc": "La commande de services est effectuée via l'Application. Une fois la demande confirmée, un contrat de prestation est conclu entre l'utilisateur (« Client ») et le Prestataire. Carchecker n'est pas partie au contrat de prestation et agit uniquement en tant qu'intermédiaire."
            },
            {
                "label": "3. Prix et Paiement",
                "desc": "Les prix des services sont indiqués sur l'Application. Les paiements sont effectués directement au Prestataire, sauf indication contraire. Carchecker peut percevoir une commission sur les transactions effectuées via l'Application."
            },
            {
                "label": "4. Annulation et Remboursement",
                "desc": "Les conditions d'annulation et de remboursement sont définies par le Prestataire. Les demandes d'annulation ou de remboursement doivent être adressées directement au Prestataire, Carchecker n'étant pas responsable de ces aspects."
            },
            {
                "label": "5. Exécution des Services",
                "desc": "Les Prestataires sont responsables de l'exécution des services conformément aux conditions spécifiées dans la demande du Client. Carchecker ne garantit pas la qualité des services et n'assume aucune responsabilité en cas de non-conformité ou de litige."
            },
            {
                "label": "6. Réclamations",
                "desc": "Toute réclamation relative à la prestation doit être adressée directement au Prestataire. Carchecker n'intervient pas dans le règlement des litiges entre le Client et le Prestataire."
            },
            {
                "label": "7. Confidentialité",
                "desc": "Les informations personnelles collectées lors de la commande sont traitées conformément à notre Politique de Confidentialité. Carchecker s'engage à protéger les données personnelles et à ne pas les divulguer sans consentement, sauf exigence légale."
            },
            {
                "label": "8. Modifications des CGV",
                "desc": "Carchecker se réserve le droit de modifier les CGV à tout moment. Les modifications seront publiées sur l'Application et entreront en vigueur dès leur mise en ligne. Votre utilisation continue de l'Application après modification constitue une acceptation des nouvelles CGV."
            },
            {
                "label": "9. Loi Applicable et Juridiction",
                "desc": "Les présentes CGV sont régies par le droit français. Tout litige relatif aux prestations de services sera soumis à la compétence exclusive des tribunaux français."
            }
        ]
    }
}

export default translations;