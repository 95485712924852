const translations = {
    home: {
        nav: {
            home: 'Home',
            download: 'Download',
            about: 'About',
            faq: 'FAQ',
            contact: 'Contact',
        },
        hero: {
            content: 'Let\'s ensure the quality of your rental with reliable and certified vehicle checks.',
            callToAction: 'Install the app now!',
        },
        download: {
            title: 'Download the app now!',
            desc: 'Get the app on your device and start using it today.',
            appleDownload: 'Download on the App Store',
            androidDownload: 'Get it on Google Play',
        },
        about: {
            title1: 'Let us take care of your vehicle',
            desc1: 'Carchecker is a platform that connects users with certified professionals for vehicle inspection and clean services. Our goal is to ensure that your vehicle is in perfect condition for your rental.',
            btn1: 'Start now',
            title2: 'Become a Cheker',
            desc2: 'You can also become a Cheker and offer your services to users. Join our platform and start earning money by providing quality services.',
            btn2: 'Learn more',
        },
        faq: {
            title: 'Frequently Asked Questions',
            questions: [
                {
                    question: 'What is CARCHECKER?',
                    answer: 'Carcheker is a platform that let you request services like vehicle inspection and clean directly from your phone. Certified professionals will go to the location of your vehicle at the time you choose to provide the service.'
                },
                {
                    question: "How does Carchecker work?",
                    answer: "Magna, minim pariatur ea sunt id. Ea sunt id, in. In sit quis, ea sunt minim. Ea sunt minim irure officia lorem. Minim irure officia, lorem ut. Lorem ut ad velit labore cillum cupidatat. Ad velit, labore cillum cupidatat consequat."
                },
                {
                    question: "Is Carchecker available in my city?",
                    answer: "Magna, minim pariatur ea sunt id. Ea sunt id, in. In sit quis, ea sunt minim. Ea sunt minim irure officia lorem. Minim irure officia, lorem ut. Lorem ut ad velit labore cillum cupidatat. Ad velit, labore cillum cupidatat consequat."
                },
                {
                    question: "How can I become a Cheker?",
                    answer: "Magna, minim pariatur ea sunt id. Ea sunt id, in. In sit quis, ea sunt minim. Ea sunt minim irure officia lorem. Minim irure officia, lorem ut. Lorem ut ad velit labore cillum cupidatat. Ad velit, labore cillum cupidatat consequat."
                },
                {
                    question: "What are the benefits of using Carchecker?",
                    answer: "Magna, minim pariatur ea sunt id. Ea sunt id, in. In sit quis, ea sunt minim. Ea sunt minim irure officia lorem. Minim irure officia, lorem ut. Lorem ut ad velit labore cillum cupidatat. Ad velit, labore cillum cupidatat consequat."
                },
                {
                    question: "How can I contact Carchecker?",
                    answer: "Magna, minim pariatur ea sunt id. Ea sunt id, in. In sit quis, ea sunt minim. Ea sunt minim irure officia lorem. Minim irure officia, lorem ut. Lorem ut ad velit labore cillum cupidatat. Ad velit, labore cillum cupidatat consequat."
                }
            ],
        },
        contact: {
            title: 'Contact Us',
            form: {
                name: {
                    label: 'Name',
                    placeholder: 'Enter your name',
                    errorMessage: 'Please enter your name',
                },
                email: {
                    label: 'Email',
                    placeholder: 'Enter your email',
                    emptyErrorMessage: 'Please enter your email',
                    invalidErrorMessage: 'Please enter a valid email',
                },
                message: {
                    label: 'Message',
                    placeholder: 'Enter your message',
                    errorMessage: 'Please enter your message',
                },
                submit: 'Submit',
            }
        }
    },
    footer: {
        termsOfUse: 'Terms of Use',
        termsOfSale: 'General Terms of Sale',
        privacyPolicy: 'Privacy Policy',
        cookiesPolicy: 'Cookies Policy',
        copyright: 'All rights reserved.',
    },
    emailConfirmed: {
        header: 'Email confirmed',
        desc: 'Your email has been confirmed successfully. You can now go back to the app and log in.',
        gotoApp: 'Please return to the mobile application to continue.',
    },
    forgotPassword: {
        header: 'Forgot password',
        email: {
            label: 'Email address',
            errorMessage: 'Please enter your email address',
            validEmailRequired: 'Please enter a valid email address',
            placeholder: "Enter your email address"
        },
        submit: 'Submit',
        success: 'Success',
        successDesc: 'A password reset link has been sent to your email. Please check your inbox and follow the instructions.',
        error: 'Error',
        submitting: 'Submitting...',
        errors: {
            tryAgainLater: 'An unexpected error occurred. Please try again later.',
            resetLinkNotSent: 'An error occurred while sending the reset link.'
        }
    },

    notFound: {
        message: 'Oops! The page you are looking for does not exist.',
        goHome: 'Go back home',
    },

    resetPassword: {
        header: 'Reset Password',
        newPassword: {
            label: 'New Password',
            placeholder: 'Enter your new password',
            emptyError: 'Please input your new password!',
            tooShortError: 'Password must be at least 8 characters long.',
        },
        confirmPassword: {
            label: 'Confirm New Password',
            placeholder: 'Confirm your new password',
            emptyError: 'Please confirm your new password!',
            noMatchError: 'The two passwords do not match!',
        },
        submitting: 'Submitting...',
        submit: 'Submit',
    },

    chekerVerif: {
        text: "Redirecting in progress, please wait...",
        invalidID: "Invalid ID.",
        failedRedirect: "Failed to redirect. Please try again later.",
        errorOccurred: "An error occurred while retrieving the redirection link.",
        noRedirect: "No redirection link available."
    },

    chekerVerificationFinished: {
        title: 'Your updates have been successfully submitted!',
        subTitle: 'Your submission is under verification. Please return to the mobile application to continue.',
    },

    ToU: {
        header: "Terms of Use (ToU)",
        content: [
            {
                "label": "1. Acceptance of the Terms",
                "desc": "The use of the Carchecker application ('Application') is subject to these Terms of Use ('ToU'). By using the Application, you accept these ToU in their entirety. If you do not accept these terms, please do not use the Application."
            },
            {
                "label": "2. Description of the Service",
                "desc": "Carchecker allows users to request services related to vehicle inspection and maintenance through the Application. The services are provided by partner service providers ('Providers') registered on the platform."
            },
            {
                "label": "3. Registration and Account",
                "desc": "To use the services of the Application, you must create an account by providing accurate and complete personal information. You are responsible for the confidentiality of your credentials and for all activities conducted on your account."
            },
            {
                "label": "4. User Obligations",
                "desc": "Users must:\n- Provide accurate information when requesting services.\n- Respect appointments and conditions established with the Providers.\n- Not use the Application for illegal or prohibited purposes."
            },
            {
                "label": "5. Liability",
                "desc": "The Carchecker application acts solely as an intermediary between users and Providers. We do not guarantee the quality of services provided by Providers and are not responsible for disputes between users and Providers."
            },
            {
                "label": "6. Privacy",
                "desc": "Your personal data is collected and processed in accordance with our Privacy Policy. We are committed to protecting your information and not disclosing it to third parties without your consent, unless required by law."
            },
            {
                "label": "7. Intellectual Property",
                "desc": "All intellectual property rights related to the Application and its content are the exclusive property of Carchecker or its licensors. You are not authorized to use these elements without prior permission."
            },
            {
                "label": "8. Changes to the ToU",
                "desc": "Carchecker reserves the right to modify these ToU at any time. The changes will be posted on the Application and will take effect upon their online publication. Your continued use of the Application constitutes acceptance of the changes."
            },
            {
                "label": "9. Termination",
                "desc": "Carchecker reserves the right to suspend or terminate your account if you violate the ToU or misuse the Application."
            },
            {
                "label": "10. Governing Law and Jurisdiction",
                "desc": "These ToU are governed by French law. Any dispute related to the Application will be subject to the exclusive jurisdiction of the French courts."
            }
        ]
    },
    GTS: {
        header: "General Terms of Sale (GTS)",
        content: [
            {
                "label": "1. Scope",
                "desc": "These General Terms of Sale ('GTS') apply to all service requests made via the Carchecker application ('Application') and provided by Providers registered on the platform."
            },
            {
                "label": "2. Order and Confirmation",
                "desc": "The order for services is placed through the Application. Once the request is confirmed, a service contract is established between the user ('Client') and the Provider. Carchecker is not a party to the service contract and acts solely as an intermediary."
            },
            {
                "label": "3. Prices and Payment",
                "desc": "The service prices are indicated on the Application. Payments are made directly to the Provider, unless otherwise specified. Carchecker may receive a commission on transactions conducted through the Application."
            },
            {
                "label": "4. Cancellation and Refund",
                "desc": "The cancellation and refund conditions are defined by the Provider. Cancellation or refund requests must be addressed directly to the Provider, with Carchecker not being responsible for these aspects."
            },
            {
                "label": "5. Service Execution",
                "desc": "Providers are responsible for the execution of services in accordance with the conditions specified in the Client's request. Carchecker does not guarantee the quality of the services and assumes no responsibility in case of non-compliance or dispute."
            },
            {
                "label": "6. Claims",
                "desc": "Any claim related to the service must be addressed directly to the Provider. Carchecker does not intervene in the resolution of disputes between the Client and the Provider."
            },
            {
                "label": "7. Privacy",
                "desc": "The personal information collected during the order is processed in accordance with our Privacy Policy. Carchecker is committed to protecting personal data and not disclosing it without consent, except as required by law."
            },
            {
                "label": "8. Changes to the GTS",
                "desc": "Carchecker reserves the right to modify the GTS at any time. The changes will be posted on the Application and will take effect upon their online publication. Your continued use of the Application after modification constitutes acceptance of the new GTS."
            },
            {
                "label": "9. Governing Law and Jurisdiction",
                "desc": "These GTS are governed by French law. Any dispute related to the service provision will be subject to the exclusive jurisdiction of the French courts."
            }
        ]
    }
}

export default translations;