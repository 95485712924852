import React, { useState, useRef, useEffect } from 'react';
import { DownloadOutlined, AppleOutlined, AndroidOutlined, InfoCircleOutlined, MailOutlined, UserOutlined, MessageOutlined } from '@ant-design/icons';
import { Menu, Button, Card, Row, Col, Collapse, Form, Input } from 'antd';
import { useTranslation } from "react-i18next";
import './HomePage.css';
import Footer from "../../components/Footer/Footer"; // Import the Footer component

const { Panel } = Collapse;

export const HomePage = () => {
    const { t } = useTranslation();

    // Refs for sections
    const homeRef = useRef(null);
    const downloadRef = useRef(null);
    const aboutRef = useRef(null);
    const faqRef = useRef(null);
    const contactRef = useRef(null);

    const items = [
        { label: t('home.nav.home'), key: 'home' },
        { label: t('home.nav.download'), key: 'download', icon: <DownloadOutlined /> },
        { label: t('home.nav.about'), key: 'about' },
        { label: t('home.nav.faq'), key: 'faq' },
        { label: t('home.nav.contact'), key: 'contact' },
    ];

    const [current, setCurrent] = useState('home');

    const handleMenuClick = (e) => {
        setCurrent(e.key);
        const scrollOptions = { behavior: 'smooth', block: 'start', inline: 'nearest' };
        if (e.key === 'home' && homeRef.current) homeRef.current.scrollIntoView(scrollOptions);
        if (e.key === 'download' && downloadRef.current) downloadRef.current.scrollIntoView(scrollOptions);
        if (e.key === 'about' && aboutRef.current) aboutRef.current.scrollIntoView(scrollOptions);
        if (e.key === 'faq' && faqRef.current) faqRef.current.scrollIntoView(scrollOptions);
        if (e.key === 'contact' && contactRef.current) contactRef.current.scrollIntoView(scrollOptions);
    };

    // Improved scroll handling function
    const handleScroll = () => {
        const sections = [
            { key: 'home', ref: homeRef },
            { key: 'download', ref: downloadRef },
            { key: 'about', ref: aboutRef },
            { key: 'faq', ref: faqRef },
            { key: 'contact', ref: contactRef },
        ];

        // Define an offset to activate a section slightly before it's fully in view
        const offset = 100;

        sections.forEach((section) => {
            const sectionRef = section.ref.current;
            if (sectionRef) {
                const { top } = sectionRef.getBoundingClientRect();
                if (top <= offset && top + sectionRef.offsetHeight > offset) {
                    setCurrent(section.key);
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const faq = t('home.faq.questions', { returnObjects: true });

    return (
        <div className="home-container">
            <div className="navbar-container">
                <img src="/android-chrome-512x512.png" alt="Logo" className="navbar-logo" />
                <Menu
                    onClick={handleMenuClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                    className="navbar-menu"
                />
            </div>
            <div className="content">
                <div className="hero-unit" id="home" ref={homeRef}>
                    <div className="hero-text">
                        <h1>CAR<span className="highlight">CHEKER</span></h1>
                        <p>{t('home.hero.content')}</p>
                        <Button type="primary" size="large" href="#download" className="cta-button">
                            {t('home.hero.callToAction')}
                        </Button>
                    </div>
                    <div className="hero-image">
                        <img src="/hero-illustration.svg" alt="Illustration" />
                    </div>
                </div>

                <div className="download-section" id="download" ref={downloadRef}>
                    <h2>{t('home.download.title')}</h2>
                    <p>{t('home.download.desc')}</p>
                    <div className="download-buttons">
                        <Button type="primary" icon={<AppleOutlined />} size="large" href="https://www.apple.com/app-store/" className="download-button">
                            {t('home.download.appleDownload')}
                        </Button>
                        <Button type="default" icon={<AndroidOutlined />} size="large" href="https://play.google.com/store" className="download-button">
                            {t('home.download.androidDownload')}
                        </Button>
                    </div>
                </div>

                <div className="about-section" id="about" ref={aboutRef}>
                    <Row gutter={[16, 16]} align="middle">
                        <Col xs={24} sm={24} md={12}>
                            <Card
                                hoverable
                                cover={<img alt="About Us" src="/about-illustration.svg" className="about-image" />}
                                className="about-card"
                            >
                                <Card.Meta
                                    title={<h2>{t('home.about.title1')}</h2>}
                                    description={<p>{t('home.about.desc1')}</p>}
                                />
                                <Button type="primary" icon={<InfoCircleOutlined />} size="large" href="#download" className="about-button">
                                    {t('home.about.btn1')}
                                </Button>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <div className="about-text">
                                <h2>{t('home.about.title2')}</h2>
                                <p>{t('home.about.desc2')}</p>
                                <Button type="primary" icon={<InfoCircleOutlined />} size="large" href="#faq" className="about-button">
                                    {t('home.about.btn2')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                {/* FAQ Section */}
                <div className="faq-section" id="faq" ref={faqRef}>
                    <h2>{t('home.faq.title')}</h2>
                    <Collapse accordion>
                        {faq.map((item, index) => (
                            <Panel header={item.question} key={index}>
                                <p>{item.answer}</p>
                            </Panel>
                        ))}
                    </Collapse>
                </div>

                {/* Contact Section */}
                <div className="contact-section" id="contact" ref={contactRef}>
                    <h2>{t('home.contact.title')}</h2>
                    <Form
                        name="contact"
                        layout="vertical"
                        onFinish={(values) => console.log('Form Values:', values)} // Replace with your form submission logic
                        style={{ maxWidth: '600px', margin: '0 auto' }}
                    >
                        <Form.Item
                            name="name"
                            label={t('home.contact.form.name.label')}
                            rules={[{ required: true, message: t('home.contact.form.name.errorMessage') }]}
                        >
                            <Input prefix={<UserOutlined />} placeholder={t('home.contact.name.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label={t('home.contact.form.email.label')}
                            rules={[
                                { required: true, message: t('home.contact.form.email.emptyErrorMessage') },
                                { type: 'email', message: t('home.contact.form.email.invalidErrorMessage') },
                            ]}
                        >
                            <Input prefix={<MailOutlined />} placeholder={t('home.contact.email.placeholder')} />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label={t('home.contact.form.message.label')}
                            rules={[{ required: true, message: t('home.contact.form.message.errorMessage') }]}
                        >
                            <Input.TextArea prefix={<MessageOutlined />} placeholder={t('home.contact.message.placeholder')} rows={4} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="contact-button">
                                {t('home.contact.form.submit')}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <Footer />
        </div>
    );
};
